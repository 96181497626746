import { useState, useMemo, useEffect, useRef, useContext } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { Button, message } from "antd";
import { InviteSubscriptionUser } from "pages/invite-user/invite-subscription-user";
import { useMutation, useSubscription } from "@apollo/client";
import {
  SUBSCRIPTION_USERS,
  SUBSCRIPTION_USER_SUBSCRIPTION_ROLES
} from "services/graphQL/subscriptions";

import {
  InviteStatusCellRenderer,
  userNameRenderer,
  userRoleRenderer
} from "components/cell-renders";
import {
  RoleSubscriptionEnum,
  SubscriptionContext
} from "context/SubscriptionProvider";
import { ColDef, GetRowIdParams } from "ag-grid-community";
import { GridLoadingIndicator } from "components/widgets";
import UserNameCellEditor from "components/cell-editor/user-name-cell-editor";
import UserRoleSelectCellEditor from "components/cell-editor/user-role-cell-editor";
import {
  MUTATION_UPDATE_SUBSCRIPTION_USER_NAME,
  MUTATION_UPDATE_SUBSCRIPTION_USER_PERMISSION
} from "services/graphQL/mutations";
import { useCIQQuery } from "hooks/ciq-gql-hooks";
import { QUERY_GET_PROJECT_LIST } from "services/graphQL/queries";
import InviteAccUserComponent from "pages/invite-user/invite-subscription-user/invite-acc-user";
import CiqLogPageHeader, {
  calculateLogPageStats
} from "components/ciq-log-page-header";
import CiqAgSearch from "components/ciq-ag-search";
import {
  FilterChipComponent,
  FilterChipComponentRef
} from "entity-app/shared-components/log-render-components/filter-chip-component";
import { getSubscriptionUserSources } from "utils/utils";
import { getUser } from "services/auth";
import {
  AcceptanceStatus,
  DialogTitles,
  ErrorMessages,
  ESubmittalMgmtModes
} from "../../constants";

// Admin can edit all users name
// Editor can edit non-admin users name
// Viewer can't edit
const hasNameCellEditAccess = (params: any) => {
  const { context, data } = params;
  if (context.hasAdminAccess) return true;
  return (
    context.subscriptionRole === RoleSubscriptionEnum.subscription_editor &&
    data?.subscription_permission?.name !== "Admin"
  );
};

///
// CIQ-5665 1. User Permission in a Subscription can be edited only by Subscription Admins.
// CIQ-5665 2. Permission can be edited of ONLY other users. A user can’t edit his own permission.
///
const hasPermissionCellEditAccess = (params: any) => {
  const { context, data } = params;
  return (
    context.hasAdminAccess && data?.user?.email !== context?.currentUser?.email
  );
};

///  After refetch the rowdata ,It help to persist Scroll Bar
const getRowId = (params: GetRowIdParams<{ user_id: string }>) =>
  params.data.user_id;

function Users() {
  const gridRef = useRef<AgGridReact>(null);
  const [isGridReady, setGridReady] = useState(false);
  const [drawer, setDrawerVisibility] = useState<boolean>(false);
  const [allUsers, setUsers] = useState([]);
  const { subscriptionRole, subscriptionId } = useContext(SubscriptionContext);
  const [currentUser]: [any, any] = useState(getUser());

  const [inviteAccUser, setInviteAccUser] = useState<{
    showInviteModel: boolean;
    inviteUserData?: any;
  }>({
    showInviteModel: false,
    inviteUserData: null
  });
  const [statsData, setStatsData] = useState<{
    displayCount: number;
    filterName: undefined | string;
  }>();
  const filterChipRef = useRef<FilterChipComponentRef>(null);

  const hasAdminAccess =
    subscriptionRole === RoleSubscriptionEnum.subscription_admin;

  const {
    data: usersData,
    error,
    loading
  } = useSubscription(SUBSCRIPTION_USERS, {
    shouldResubscribe: true
  });

  const { data: userSubscriptionRoles } = useSubscription(
    SUBSCRIPTION_USER_SUBSCRIPTION_ROLES,
    {
      shouldResubscribe: true
    }
  );

  const { data: projectListData } = useCIQQuery(QUERY_GET_PROJECT_LIST, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        status_id: { _neq: AcceptanceStatus.DEACTIVATED }
      }
    }
  });

  const isIntegratedProjectExist = useMemo(() => {
    if (projectListData && projectListData?.project_list_func.length > 0) {
      const isIntegratedProjExist = projectListData?.project_list_func.some(
        (proj: any) => proj?.mode === ESubmittalMgmtModes.INTEGRATION_MODE
      );
      return isIntegratedProjExist;
    }
    return false;
  }, [projectListData]);

  const [updateUserPermission] = useMutation<any>(
    MUTATION_UPDATE_SUBSCRIPTION_USER_PERMISSION
  );

  const [updateUserName] = useMutation<any>(
    MUTATION_UPDATE_SUBSCRIPTION_USER_NAME
  );

  const setDrawerAction = (drawerAction: boolean) => {
    setDrawerVisibility(drawerAction);
  };

  useEffect(() => {
    if (isGridReady && gridRef && gridRef.current) {
      if (loading) {
        gridRef.current?.api.showLoadingOverlay();
      } else {
        gridRef.current?.api.hideOverlay();
      }
    }
    if (error) {
      gridRef.current?.api.hideOverlay();
      message.error(error.message);
      return;
    }
    if (!usersData) return;
    setUsers(usersData.subscription_users);
  }, [error, loading, usersData, isGridReady]);

  // Each Column Definition results in one Column.
  const columnDefs: ColDef[] = useMemo<ColDef[]>(() => {
    return [
      {
        headerName: "Serial No.",
        valueGetter: "node.rowIndex + 1",
        headerTooltip: "SERIAL NO.",
        tooltipField: "node.rowIndex + 1",
        menuTabs: [],
        width: 100,
        sortable: false,
        editable: false
      },
      {
        valueGetter: (params: any) => {
          return `${params.data.user?.first_name} ${params.data.user?.last_name}`;
        },
        field: "name",
        headerName: "Name",
        headerTooltip: "NAME",
        tooltipValueGetter: (params: any) => {
          return `${params.data.user?.first_name} ${params.data.user?.last_name}`;
        },
        menuTabs: [],
        comparator: (valueA: any, valueB: any) => {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        },
        editable: hasNameCellEditAccess,
        cellRenderer: userNameRenderer,
        cellEditor: UserNameCellEditor,
        cellClass: (params: any) => {
          return hasNameCellEditAccess(params) ? "cell-editable" : "";
        },
        sort: "asc"
      },
      {
        valueGetter: (params: any) => {
          return params.data.user.email;
        },
        headerName: "Email",
        headerTooltip: "EMAIL",
        tooltipField: "user.email",
        menuTabs: [],
        field: "email",
        colId: "email"
      },
      {
        headerName: "Company",
        headerTooltip: "COMPANY",
        filter: true,
        menuTabs: ["filterMenuTab"],
        field: "subscription_vendor.name",
        colId: "company",
        tooltipValueGetter: ({ value }: any) => value
      },
      {
        valueGetter: (params: any) => {
          return params.data.subscription_permission?.name;
        },
        field: "permission",
        colId: "permission",
        headerName: "Permission",
        headerTooltip: "PERMISSION",
        tooltipField: "subscription_permission.name",
        filter: true,
        menuTabs: ["filterMenuTab"],
        editable: hasPermissionCellEditAccess,
        cellRenderer: userRoleRenderer,
        cellEditor: UserRoleSelectCellEditor,
        cellClass: (params: any) => {
          return hasPermissionCellEditAccess(params) ? "cell-editable" : "";
        }
      },
      {
        valueGetter: (params: any) => {
          return params.data.user.phone;
        },
        field: "phone",
        headerName: "Phone Number",
        headerTooltip: "PHONE NUMBER",
        tooltipField: "user.phone",
        menuTabs: [],
        colId: "phone"
      },
      {
        colId: "source",
        headerName: "Source",
        menuTabs: ["filterMenuTab"],
        hide: !isIntegratedProjectExist,
        valueGetter: ({ data }) => {
          const sources = getSubscriptionUserSources(data);
          return sources.join(",");
        }
      },
      {
        field: "status_id",
        colId: "status_id",
        headerName: "Invite Status",
        headerTooltip: "INVITE STATUS",
        minWidth: 220,
        menuTabs: [],
        cellRenderer: InviteStatusCellRenderer,
        cellRendererParams: { inviteType: "Subscription" },
        comparator: (valueA: any, valueB: any) => {
          return AcceptanceStatus[valueA]
            .toLowerCase()
            .localeCompare(AcceptanceStatus[valueB].toLowerCase());
        },
        tooltipValueGetter: (params) => {
          let status = "";
          switch (params.data.status_id) {
            case 1:
              status = "Pending";
              break;
            case 2:
              status = "Active";
              break;
            case 3:
              status = "Deactivated";
              break;

            default:
              break;
          }
          return status;
        }
      }
    ];
  }, [isIntegratedProjectExist]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef: {} = useMemo(() => {
    return {
      sortable: true,
      autoSizeAllColumns: true,
      resizable: true
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const onUserNameCellEdit = async (event: any) => {
    const firstName = event.newValue.firstNameValue?.trim();
    const lastName = event.newValue.lastNameValue?.trim();

    const oldData = { ...event.data };

    if (
      firstName === "" ||
      (firstName === oldData.user.first_name &&
        lastName === oldData.user.last_name)
    )
      return;

    event.node.setData({
      ...oldData,
      user: {
        first_name: firstName,
        last_name: lastName
      }
    });

    const variables = {
      pk_columns: { id: event.data?.user_id },
      _set: { first_name: firstName, last_name: lastName }
    };

    try {
      const updateResponse = await updateUserName({
        variables
      });
      if (updateResponse?.data?.update_user_by_pk) {
        message.success("User name updated successfully.");
      } else {
        event.node.setData(oldData);
      }
    } catch (ex) {
      message.error("Failed to update");
      event.node.setData(oldData);
    }
  };

  const onUserPermissionCellEdit = async (event: any) => {
    if (event.newValue?.trim() === event.oldValue?.trim()) return;

    const userRole = userSubscriptionRoles?.subscription_permission?.find(
      (role: any) => role?.name === event.newValue
    );

    if (userRole?.id) {
      const oldData = { ...event.data };
      event.node.setData({
        ...oldData,
        subscription_permission: userRole
      });

      const variables = {
        where: {
          subscription_id: { _eq: subscriptionId },
          user_id: { _eq: event.data.user_id }
        },
        _set: { subscription_permission_id: userRole?.id }
      };

      try {
        const updateResponse = await updateUserPermission({
          variables
        });
        if (updateResponse?.data?.update_subscription_users) {
          message.success("User permission updated successfully.");
        } else {
          message.error("Failed to update.");
          event.node.setData(oldData);
        }
      } catch (ex) {
        message.error("An error occured");
        event.node.setData(oldData);
      }
    }
  };

  const cellEditRequest = async (event: any) => {
    if (!event.newValue) return;
    if (event.column.colId === "name") {
      onUserNameCellEdit(event);
      return;
    }

    if (event.column.colId === "permission") {
      onUserPermissionCellEdit(event);
    }
  };

  const renderUsers = () => {
    if (usersData && usersData.subscription_users.length === 0) {
      return (
        <div>Start by adding users of your company you want work with</div>
      );
    }

    const onFiltersApplied = () => {
      filterChipRef.current?.onFilterApplied();
      setStatsData(
        calculateLogPageStats({
          gridRef
        })
      );
    };

    return (
      <AgGridReact<any>
        className="ag-theme-alpine"
        ref={gridRef}
        onGridReady={() => {
          setGridReady(true);
          gridRef?.current?.api?.sizeColumnsToFit();
        }}
        rowData={allUsers}
        columnDefs={columnDefs} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        animateRows // Optional - set to 'true' to have rows animate when sorted
        loadingOverlayComponent={GridLoadingIndicator}
        getRowId={getRowId} // After refetch the rowdata ,It help to persist Scroll Bar
        readOnlyEdit
        singleClickEdit
        onCellEditRequest={cellEditRequest}
        stopEditingWhenCellsLoseFocus
        suppressDragLeaveHidesColumns
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
        context={{
          hasAdminAccess,
          subscriptionRole,
          userSubscriptionRoles,
          setInviteAccUser,
          currentUser
        }}
        onFilterChanged={onFiltersApplied}
      />
    );
  };

  const headerItems = [
    <CiqAgSearch
      key="SubscriptionUsers_search"
      gridRef={gridRef}
      placeholder="Search Users"
    />,
    <FilterChipComponent
      key="SubscriptionUsers_FilterChip"
      columnDefs={columnDefs}
      gridRef={gridRef}
      ref={filterChipRef}
    />,
    <Button
      key="SubscriptionUsers_AddUser"
      onClick={() => setDrawerVisibility(true)}
      title={
        subscriptionRole <= RoleSubscriptionEnum.subscription_viewer
          ? ErrorMessages.PermissionNotGranted
          : "Add User"
      }
      disabled={subscriptionRole <= RoleSubscriptionEnum.subscription_viewer}
    >
      Add User
    </Button>
  ];

  return (
    <>
      <CiqLogPageHeader
        {...{
          entityName: "USERS",
          titleParam: {
            title: "Users",
            totalCount: allUsers?.length,
            filterStats: statsData
          },
          gridRef,
          items: headerItems
        }}
      />
      <div className="grow  px-2 pb-2">{renderUsers()}</div>
      {drawer && (
        <InviteSubscriptionUser
          existingUsers={allUsers || []}
          setDrawerOpen={setDrawerAction}
          showDrawerOpen={drawer}
          modelTitle={DialogTitles.addUser}
        />
      )}
      {inviteAccUser.showInviteModel && (
        <InviteAccUserComponent
          setDrawerOpen={setInviteAccUser}
          showDrawerOpen={inviteAccUser.showInviteModel}
          modelTitle={DialogTitles.inviteUser}
          userData={inviteAccUser.inviteUserData}
          isFromProjectUser={false}
        />
      )}
    </>
  );
}

export default Users;
