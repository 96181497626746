import { Tag } from "antd";
import CriticalActivityIcon from "components/svg-icons/critical-activity-icon";

function CriticalActivityTag() {
  return (
    <Tag
      className="flex items-center justify-center w-full border-[#ADC6FF] bg-[#F0F5FF] text-[#2F54EB] rounded-[5px]"
      style={{ height: 24 }}
      icon={<CriticalActivityIcon />}
    >
      Critical Activity
    </Tag>
  );
}

export default CriticalActivityTag;
