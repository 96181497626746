import { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  DATE_FORMAT_MMDDYYYY,
  DATE_FORMAT_YYYYMMDD,
  DateFilter,
  DateUtils
} from "utils/dateutils";
import { RiskLevelType } from "components/date-block/models";
import { TActivitiesV3, TLinkedMaterial, TLinkedSubmittal } from "./models";
import "./style.scss";
import { getRiskAssessmentForEntity } from "./utils";
import MaterialsGroupDiffV6 from "./MaterialGroupDiffV6";
import SubmittalGroupDiffV6 from "./SubmittalGroupDiffV6";
import { EntityType } from "../../../../constants/index";
import CriticalActivityTag from "../critical-activity-tag";

function ImpactedSubmittalEntityBoxHeader({
  submittal,
  projectId,
  linkedToEndDate
}: {
  submittal: any;
  projectId: any;
  linkedToEndDate: any;
}) {
  return (
    <div className="impactedEntityBoxHeader">
      <h4 className="font-bold m-0">
        Submittal:
        <Link
          to={`/project/${projectId}/submittals/${submittal.submittal_id}`}
          className="pl-2"
        >
          <i className="mr-2">
            {submittal.SDB?.old?.submittal_sequence_id} -{" "}
            {submittal.SDB?.old?.spec_no}
          </i>
          {submittal.SDB.old.title}
        </Link>
      </h4>
      <div className="text-right font-semibold">
        {linkedToEndDate ? "Linked with End Date" : ""}
      </div>
    </div>
  );
}

function ImpactedMaterialEntityBoxHeader({
  material,
  projectId,
  linkedToEndDate,
  isImplicit
}: {
  material: any;
  projectId: any;
  linkedToEndDate: any;
  isImplicit: any;
}) {
  return (
    <div className="impactedEntityBoxHeader">
      <h4 className="font-bold m-0">
        Material:
        {material.implicit !== true ? (
          <Link
            to={`/project/${projectId}/materials/${material.material_id}`}
            className="pl-2"
          >
            {isImplicit ? (
              `Material for Submittal ${material?.name}`
            ) : (
              <span>
                <i className="mr-2">
                  {material.MDB?.old?.material_sequence_id}
                </i>
                {material.MDB.old.name}
              </span>
            )}
          </Link>
        ) : (
          `Material for Submittal ${material?.name}`
        )}
      </h4>
      <div className="text-right font-semibold">
        {linkedToEndDate ? "Linked with End Date" : ""}
      </div>
    </div>
  );
}

function ActivityInfo({ activity }: { activity: TActivitiesV3 }) {
  let activityInfo: any = {};
  const getActivityInfo = (linkedTasks: any) => {
    const oldGoverningTasks: any[] = linkedTasks[0]?.old_governing_task;
    const oldActivity = oldGoverningTasks?.length ? oldGoverningTasks[0] : null;

    const newGoverningTasks: any[] = linkedTasks[0]?.new_governing_task;
    const newActivity = newGoverningTasks?.length ? newGoverningTasks[0] : null;

    return {
      old_critical: oldActivity?.critical,
      old_float: oldActivity?.total_slack,
      new_critical: newActivity?.critical,
      new_float: newActivity?.total_slack
    };
  };

  if (activity?.linked_submittals?.length) {
    activityInfo = getActivityInfo(activity?.linked_submittals);
  } else if (activity?.linked_materials?.length) {
    activityInfo = getActivityInfo(activity?.linked_materials);
  }

  const titleDiv = (
    <h4 className="m-0">
      <span className="font-bold ">Impacting Activity:</span>
      <i className="mr-2 pl-2">{activity.id}</i>
      {activity.text}
    </h4>
  );
  if (activity.deleted) {
    return (
      <div className="impactingActivity">
        {titleDiv}
        <div className="text-right">
          Linked activity has been removed in the selected schedule. Please
          relink to a suitable activity, if required.
        </div>
      </div>
    );
  }

  const activityInfoDiv = (
    title1: string,
    title2: string,
    value: any,
    float: number,
    isCritical: boolean,
    isOldActivity: boolean
  ) => {
    const parentDivClass = isOldActivity ? "bg-gray-50" : "bg-blue-50";
    const verticalLineDiv = isOldActivity ? "bg-gray-200" : "bg-blue-200";
    const floatClass = isCritical
      ? "text-[#000000D9] font-bold"
      : "text-[#00000099] font-normal";
    return (
      <div className="flex flex-col space-y-1 w-full mt-6">
        <div className="flex justify-between items-end w-full h-6">
          <div className="text-[#00000099] font-medium text-xs">{title1}</div>
          {isCritical && (
            <div>
              <CriticalActivityTag />
            </div>
          )}
        </div>
        <div
          className={`flex justify-between w-full text-sm h-[38px] ${parentDivClass}`}
        >
          <div className="flex items-center space-x-2 w-full px-2">
            <div className={`-ml-2 h-full w-1 ${verticalLineDiv}`} />
            <div className="truncate">
              <span className="text-[#242424] font-medium ">{title2}:</span>{" "}
              <span className="text-[#00000099] font-normal">
                {DateUtils.format(value, DATE_FORMAT_MMDDYYYY)}
              </span>
            </div>
          </div>
          <div className="flex items-center space-x-2 mr-2 h-full">
            <div className="text-[#242424] font-medium">Float:</div>
            <div className={floatClass}>{float}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      {titleDiv}
      <div className="grid grid-cols-5 gap-2">
        <div className="flex col-start-2 col-end-5 space-x-6">
          {activityInfoDiv(
            "Before",
            "Old Activity Date",
            activity.old_start,
            activityInfo.old_float,
            activityInfo.old_critical,
            true
          )}
          {activityInfoDiv(
            "After",
            "New Activity Date",
            activity.new_start,
            activityInfo.new_float,
            activityInfo.new_critical,
            false
          )}

          <div className="flex-col font-medium mt-7">
            <div className="text-xs text-[#00000099] mt-1">Delta</div>
            <div className="flex space-x-1 text-sm text-[#000000D9] mt-3">
              <span>
                {DateFilter.customComparator(
                  activity.new_start,
                  activity.old_start,
                  DATE_FORMAT_YYYYMMDD
                ) < 0
                  ? "-"
                  : "+"}
              </span>
              {activity.delta}
              <span>days</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ScheduleImpactV3({
  activity,
  entityType
}: {
  activity: TActivitiesV3;
  entityType: EntityType;
}) {
  const { projectId } = useParams() as any;
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div>
      {entityType === EntityType.Submittal &&
        (activity.linked_submittals || []).map(
          (submittal: TLinkedSubmittal, i: number) => {
            const riskAssessment = getRiskAssessmentForEntity(submittal?.SDB);
            const key = `${i}-${submittal.submittal_id}`;
            const linkedToEndDate =
              submittal.old_governing_task?.find(
                (x) => x.activity_id === activity.id
              )?.linked_to_end_date || false;

            return (
              <div key={key}>
                <ImpactedSubmittalEntityBoxHeader
                  submittal={submittal}
                  projectId={projectId}
                  linkedToEndDate={linkedToEndDate}
                />
                <div>
                  <div className="impactBoxContainerContent">
                    {riskAssessment ? (
                      <div>
                        <b className="mr-1">
                          Risk assessment after Schedule Change:
                        </b>
                        <span
                          className={
                            riskAssessment.level === RiskLevelType.High
                              ? "text-[#F3523A]"
                              : ""
                          }
                        >
                          {riskAssessment.text}
                        </span>
                      </div>
                    ) : null}
                    <ActivityInfo activity={activity} />
                  </div>
                  <div className="impactBoxContainerContent" ref={containerRef}>
                    <SubmittalGroupDiffV6
                      submittal={submittal}
                      activityComparison={activity}
                      projectId={projectId}
                    />
                  </div>
                </div>
              </div>
            );
          }
        )}

      {entityType === EntityType.Material &&
        (activity.linked_materials || []).map(
          (material: TLinkedMaterial, i: number) => {
            const riskAssessment = getRiskAssessmentForEntity(material?.MDB);
            const key = `${i}-${material.material_id}`;
            const linkedToEndDate =
              material.old_governing_task?.find(
                (x) => x.activity_id === activity.id
              )?.linked_to_end_date || false;

            const isImplicit = material.MDB.old?.implicit;

            return (
              <div key={key}>
                <ImpactedMaterialEntityBoxHeader
                  material={material}
                  projectId={projectId}
                  linkedToEndDate={linkedToEndDate}
                  isImplicit={isImplicit}
                />
                <div key={key}>
                  <div className="impactBoxContainerContent">
                    {riskAssessment ? (
                      <div>
                        <b className="mr-1">Risk assessment:</b>
                        <span
                          className={
                            riskAssessment.level === RiskLevelType.High
                              ? "text-[#F3523A]"
                              : ""
                          }
                        >
                          {riskAssessment.text}
                        </span>
                      </div>
                    ) : null}

                    <ActivityInfo activity={activity} />
                  </div>
                  <div className="impactBoxContainerContent" ref={containerRef}>
                    <MaterialsGroupDiffV6
                      material={material}
                      activityComparison={activity}
                      projectId={projectId}
                    />
                  </div>
                </div>
              </div>
            );
          }
        )}
    </div>
  );
}
export default ScheduleImpactV3;
