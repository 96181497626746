import { ArrowLeftOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { ErrorBoundary } from "@sentry/react";
import { AgGridReact } from "ag-grid-react";
import { Button, message } from "antd";
import { GanttActivityTypes, ProjectPermissionEnum } from "constants/index";
import { isPermissionNotGrantted } from "context/ProjectProvider";
import { ScheduleTaskType } from "pages/submittal-schedule-linking/models";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DateFilter, DateUtils } from "utils/dateutils";
import {
  QUERY_GANTT_TASKS_LIST,
  QUERY_NON_SCHEDULE_IMPORT_PERMISSION_USR_GANTT_TASKS
} from "services/graphQL/queries";
import {
  MenuItemDef,
  GridOptions,
  GetContextMenuItemsParams,
  ColDef,
  ValueFormatterParams
} from "ag-grid-community";
import ExpandIconPath from "assets/svg/expand-icon.svg";
import CollapseIconPath from "assets/svg/collapse-icon.svg";
import { GridLoadingIndicator } from "components/widgets";
import { getFileFromS3 } from "services/schedule-versions-services";
import SearchInputField from "pages/submittal-schedule-linking/search-input";
import { useHistory, useParams } from "react-router";
import CriticalActivityTag from "../../critical-activity-tag";

function ScheduleDetailspage(props: {
  gqlClientForProject: any;
  tokenContents: any;
  selectedfile: any;
}) {
  const { gqlClientForProject, tokenContents, selectedfile } = props;
  const gridRef = useRef<AgGridReact<ScheduleTaskType>>(null);
  const [isGridReady, setGridReady] = useState(false);
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [treeRowData, setTreeRowData] = useState<Array<ScheduleTaskType>>();
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const { projectId } = useParams() as any;

  const getSearchString = (str: string) => {
    setSearchValue(str);
  };

  const { data: tasksData, loading: taskLoading } = useQuery(
    isPermissionNotGrantted(
      ProjectPermissionEnum.ImportSchedule,
      tokenContents?.role!
    )
      ? QUERY_NON_SCHEDULE_IMPORT_PERMISSION_USR_GANTT_TASKS
      : QUERY_GANTT_TASKS_LIST,
    {
      client: gqlClientForProject,
      fetchPolicy: "no-cache"
    }
  );

  function setNodesCollapseandExpand(id: string, isExpanded: boolean) {
    gridRef.current?.api.forEachNode((node) => {
      if (node.data?.source_task_id === id) {
        // const isExpanded = !node.expanded;
        gridRef.current?.api.setRowNodeExpanded(node, isExpanded);
        // eslint-disable-next-line array-callback-return
        node.allLeafChildren.map((leafNode) => {
          gridRef.current?.api.setRowNodeExpanded(
            leafNode,
            leafNode.expanded === isExpanded
              ? leafNode.expanded
              : !leafNode.expanded
          );
        });
      }
    });
  }

  function criticalActivityCellRenderer(params: any) {
    if (params.data.critical && params.data.type !== GanttActivityTypes.Wps) {
      return (
        <div className="flex items-center justify-center h-full">
          {CriticalActivityTag()}
        </div>
      );
    }
    return "";
  }

  const [columnDefs] = useState<ColDef[]>([
    {
      colId: "source_task_id",
      field: "source_task_id",
      headerName: "Activity ID",
      headerTooltip: "ACTIVITY ID",
      tooltipField: "source_task_id",
      showRowGroup: true,
      filter: true,
      width: 60,
      cellRenderer: "agGroupCellRenderer",
      sort: "desc"
    },
    {
      colId: "text",
      field: "text",
      headerName: "Activity Name",
      headerTooltip: "ACTIVITY NAME",
      tooltipField: "text",
      minWidth: 300,
      filter: true
    },
    {
      colId: "start_date",
      field: "$raw.Start",
      headerName: "Start Date",
      headerTooltip: "START DATE",
      valueGetter: ({ data }: any) => {
        if (selectedfile?.is_active) {
          return data?.start_date
            ? DateUtils.format(data?.start_date, "MM-DD-YYYY")
            : "";
        }
        return data?.$raw?.Start
          ? DateUtils.format(data?.$raw?.Start, "MM-DD-YYYY")
          : "";
      },
      comparator: DateFilter.comparator,
      tooltipValueGetter: ({ data }: any) => {
        if (selectedfile?.is_active) {
          return data?.start_date
            ? DateUtils.format(data?.start_date, "MM-DD-YYYY")
            : "";
        }
        return data?.$raw?.Start
          ? DateUtils.format(data?.$raw?.Start, "MM-DD-YYYY")
          : "";
      },
      minWidth: 120,
      maxWidth: 120,
      filter: false,
      getQuickFilterText: () => ""
    },
    {
      colId: "end_date",
      field: "$raw.Finish",
      headerName: "End Date",
      headerTooltip: "END DATE",
      valueGetter: ({ data }: any) => {
        if (selectedfile?.is_active) {
          return data?.end_date
            ? DateUtils.format(data?.end_date, "MM-DD-YYYY")
            : "";
        }
        return data?.$raw?.Finish
          ? DateUtils.format(data?.$raw?.Finish, "MM-DD-YYYY")
          : "";
      },
      comparator: DateFilter.comparator,
      tooltipValueGetter: ({ data }: any) => {
        if (selectedfile?.is_active) {
          return data?.end_date
            ? DateUtils.format(data?.end_date, "MM-DD-YYYY")
            : "";
        }
        return data?.$raw?.Finish
          ? DateUtils.format(data?.$raw?.Finish, "MM-DD-YYYY")
          : "";
      },
      minWidth: 120,
      maxWidth: 120,
      filter: false,
      getQuickFilterText: () => ""
    },
    {
      colId: "duration",
      field: "duration",
      headerName: "Duration",
      headerTooltip: "DURATION",
      minWidth: 100,
      maxWidth: 100,
      filter: true,
      valueGetter: (params: any) => {
        const duration = Math.floor((params?.data?.duration || 0) / 8);
        if (duration === 0) {
          return 1;
        }
        return duration;
      },
      tooltipValueGetter: (params: any) => {
        const duration = Math.floor((params?.data?.duration || 0) / 8);
        if (duration === 0) {
          return 1;
        }
        return duration;
      },
      getQuickFilterText: () => ""
    },
    {
      colId: "critical",
      field: "critical",
      headerName: "Critical Activity",
      headerTooltip: "Critical Activity",
      cellRenderer: criticalActivityCellRenderer,
      minWidth: 170,
      maxWidth: 170,
      filter: "agSetColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          return params?.value === "true" ? "Critical" : "Non Critical";
        },
        comparator: (valA: any, valB: any) => {
          if (valA === valB) return 0;
          return valA > valB ? -1 : 1;
        }
      },
      getQuickFilterText: () => ""
    },
    {
      colId: "total_slack",
      field: "total_slack",
      headerName: "Float",
      headerTooltip: "Float",
      cellStyle: (params) => {
        if (params?.data?.critical) {
          return { fontWeight: "bold" };
        }
        return { fontWeight: "normal" };
      },
      valueGetter: (params: any) => {
        if (
          !params?.data?.total_slack ||
          params.data.type === GanttActivityTypes.Wps
        )
          return "";
        const float = Math.floor((params?.data?.total_slack || 0) / 8);
        return `${float} days`;
      },
      tooltipValueGetter: (params: any) => params?.value,
      getQuickFilterText: () => ""
    }
  ]);

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const result: (string | MenuItemDef)[] = [
        {
          // custom item
          name: "Expand All",
          action: () => {
            setNodesCollapseandExpand(
              params?.node?.data?.source_task_id || "",
              true
            );
          },
          icon: `<img src='${ExpandIconPath}'>`
        },
        {
          // custom item
          name: "Collapse All",
          action: () => {
            setNodesCollapseandExpand(
              params?.node?.data?.source_task_id || "",
              false
            );
          },
          icon: `<img src='${CollapseIconPath}'>`
        }
      ];

      return (params?.node?.allLeafChildren || []).length > 1 ? result : [];
    },
    []
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: false,
      sortable: false,
      resizable: true,
      editable: false,
      menuTabs: [],
      getContextMenuItems,
      cellRendererParams: {
        suppressCount: true
      }
    };
  }, [getContextMenuItems]);

  const gridOptions: GridOptions = {
    defaultColDef,
    suppressCopyRowsToClipboard: true,
    getContextMenuItems
  };

  useEffect(() => {
    const filepath = selectedfile?.import_log?.resolved_values_s3_prefix;
    if (selectedfile?.is_active) {
      if (tasksData) {
        const filteredTasks = tasksData?.gantt_tasks.filter(
          (ts: ScheduleTaskType) => ts.type !== 3 && ts.type !== 4
        );

        const addParent = (t: ScheduleTaskType, org: Array<any>) => {
          if (t.parent) {
            const parent = filteredTasks?.find(
              (ts: ScheduleTaskType) => ts.id === t.parent
            );
            if (parent) addParent(parent, org);
          }
          org.push(t.id);
        };
        const tree = filteredTasks?.map((t: ScheduleTaskType) => {
          const org: any[] = [];
          if (t) addParent(t, org);
          return { org, ...t };
        });
        setTreeRowData(tree);
        gridRef?.current?.api?.hideOverlay();
      } else if (!taskLoading) {
        gridRef?.current?.api?.hideOverlay();
        gridRef?.current?.api?.showNoRowsOverlay();
        setTreeRowData([]);
      }
    } else if (filepath) {
      getFileFromS3(filepath).then((responseData) => {
        if (responseData?.error) {
          gridRef?.current?.api?.hideOverlay();
          gridRef?.current?.api?.showNoRowsOverlay();
          setTreeRowData([]);
          message.error("Unable to view selected schedule, please try again.");
          return;
        }
        if (responseData) {
          const addParent = (t: ScheduleTaskType, org: Array<any>) => {
            if (t?.parent) {
              const parent = responseData?.data?.data?.find(
                (ts: ScheduleTaskType) => ts.id === t.parent
              );
              if (parent) addParent(parent, org);
            }
            if (t) {
              org.push(t?.id);
            }
          };
          const tree = responseData?.data?.data?.map((t: ScheduleTaskType) => {
            const org: any[] = [];
            if (t) addParent(t, org);
            return { org, ...t };
          });
          setTreeRowData(tree);
          gridRef?.current?.api?.hideOverlay();
        }
      });
    } else {
      gridRef?.current?.api?.hideOverlay();
      gridRef?.current?.api?.showNoRowsOverlay();
    }
  }, [tasksData, selectedfile, taskLoading]);

  useEffect(() => {
    function handleResize() {
      if (isGridReady && gridRef && gridRef.current) {
        gridRef?.current?.api?.sizeColumnsToFit();
      }
    }
    window.addEventListener("resize", handleResize);
  }, [isGridReady]);

  const getDataPath = useMemo(() => {
    return (data: any) => {
      return data.org;
    };
  }, []);

  return (
    <div className=" w-full px-2 space-y-2 h-full">
      <div className="flex ">
        <div className="flex w-full items-center gap-6">
          <Button
            icon={<ArrowLeftOutlined />}
            className="text-one text-black !bg-transparent !border-0 px-0 !shadow-none"
            onClick={() => {
              // goToScheduleDetailsPage();
              history.replace(
                `/project/${projectId}/schedule/project-schedule`
              );
            }}
          >
            All Schedules
          </Button>
          <div className="w-full text-base font-bold text-[#3b3b3b]">
            {selectedfile?.schedule_name}
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end w-full">
          <SearchInputField
            gridRef={gridRef}
            searchText={searchValue}
            getSearchText={getSearchString}
          />
          {!selectedfile?.is_active && (
            <Button
              hidden={isPermissionNotGrantted(
                ProjectPermissionEnum.ImportSchedule,
                tokenContents?.role!
              )}
              onClick={() => {
                history.push(
                  `/project/${projectId}/schedule/change-impact?&selectedVersionId=${selectedfile.import_log.id}`
                );
              }}
            >
              Compare with active version
            </Button>
          )}
        </div>
      </div>
      <div className="h-full">
        <ErrorBoundary>
          <div style={containerStyle}>
            <div style={gridStyle} className="ag-theme-alpine">
              <AgGridReact<ScheduleTaskType>
                ref={gridRef}
                rowData={treeRowData!}
                columnDefs={columnDefs}
                gridOptions={gridOptions}
                defaultColDef={defaultColDef}
                animateRows
                onGridReady={() => {
                  setGridReady(true);
                  gridRef.current?.api.sizeColumnsToFit();
                }}
                loadingOverlayComponent={GridLoadingIndicator}
                rowSelection="single"
                treeData
                groupDefaultExpanded={-1}
                getDataPath={getDataPath}
                groupDisplayType="custom"
                suppressCellFocus
                suppressDragLeaveHidesColumns
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                excludeChildrenWhenTreeDataFiltering
              />
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default ScheduleDetailspage;
